import { useEffect, useState } from "react";
import Navbar from "../../components/NavBar";
import useModal from './df-model';
import Loader from "../../components/Loading/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInAsUser from "../../components/SignInAsUser";
import { Button, Spinner } from "react-bootstrap";
import AddEditFlyerModalPopup from "./add-edit-flyer-modal"; 
import DeleteFlyerModalPopup from "./delete-flyer-modal"; 
import { useHttpClient } from "../../hooks/HttpHook";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import { Link } from "react-router-dom";

const DigitalFlyersIndex = () => {

    const { sendRequest } = useHttpClient();
    const [checked, setChecked] = useState(false);

    const { idOfAlert, 
        openAddEditFlyerModal, 
        closeAddEditFlyerModal, 
        addEditFlyerModal,
        isLoading,
        setIsLoading,
        showLoadMoreButton,
        handleLoadMore,
        modulesList,
        fetchData,
        flyerData,
        showDeleteFlyer,
        handleFlyerDelete,
        openDeleteFlyerModal, 
        closeDeleteFlyerModal,
        setModulesList,
        initLoadMore,
        mcategoryName
    } = useModal();

    useEffect(() => {
        fetchData(1, true);
    }, []);

    const onStatusChangeHandler = async (event, categoryId) => {

        const newValue = event.target.checked;
        const body = {
            id: categoryId,
            status: newValue
        };

        try {
            setIsLoading(true);
            const response = await sendRequest(
                EndPoint.CHANGE_FLYER_STATUS,
                "POST",
                body
            );
            if (!response.data) {
                throw new Error('Failed to update value in the database');
            }

            const moduleIndex = modulesList.findIndex(value => value._id == categoryId);
            if(moduleIndex != -1) {
                const newModuleList = [...modulesList];
                newModuleList[moduleIndex].status = newValue;
                setModulesList(newModuleList);
            }
            
            // on success fetch again 
            // fetchData();
            setIsLoading(false);
        } catch (error) {
            console.error('Error updating value in the database:', error);
            setChecked((prevChecked) => !prevChecked);
        }
    };

    return (
        <>
            <Navbar />
            <ToastContainer />
            <div className="main-cover">
                <div className="container">
                    <div className="row mt-0 mb-4 category-top category-top-flyer">
                        <div className="col-md-6 category-title">DIGITAL FLYERS</div>

                        <span className="col-md-6 text-md-right">
                            <Button className="send-new-alert-button" variant="primary" onClick={openAddEditFlyerModal} >
                                CREATE NEW FLYER CATEGORY
                            </Button>
                        </span>

                    </div>

                    <div className="table-responsive">
                        <table id="tezst" className="table table-striped digital-flyer-table">
                            <thead>
                                <tr>
                                    <th>Categories</th>
                                    <th className="text-center">File Counts</th>
                                    <th className="text-center">Status</th>
                                    <th colSpan={2} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    modulesList.length > 0 ?
                                        modulesList.map((element, index) => {
                                            return (
                                                <tr key={element._id}>
                                                    <td title={element.categoryName}>                                                                                                            
                                                        <Link className="fliyer-list-title" to={`${Constants.FRONT_BASE_PATH}/digital-flyers/${element._id}`}>{element.categoryName.length > 100 ? `${element.categoryName.slice(0, 100)}...` : element.categoryName}</Link>
                                                    </td>
                                                    <td className="text-center" width="200">
                                                        <span className="mFileCount">
                                                            {/* {`(${element.fileCount}) file${element.fileCount > 1 ? 's' : ''}`} */}
                                                            {element.fileCount}
                                                        </span>
                                                    </td>
                                                    <td width="100">

                                                        <div className="custom-control custom-switch">
                                                            <input
                                                                name="status"
                                                                checked={element.status}
                                                                onChange={(event) => onStatusChangeHandler(event, element._id)}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id={`category-custom-switch_${element._id}`}
                                                            />
                                                            <label className="custom-control-label" htmlFor={`category-custom-switch_${element._id}`}>
                                                                <div className="custom-switch-text"></div>
                                                            </label>
                                                        </div>

                                                    </td>

                                                    <td width="100" className="text-right">
                                                        <button
                                                            onClick={(event) => openAddEditFlyerModal(event, element._id, element.categoryName)}
                                                            className="action-icons btn btn-secondary"
                                                        >
                                                            EDIT
                                                        </button>
                                                    </td>

                                                    <td width="100" className="text-right">
                                                        <Button
                                                            onClick={(event) => openDeleteFlyerModal(event, element._id)}
                                                            className="action-icons"
                                                        >
                                                            DELETE
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    : <tr><td colSpan="4">{isLoading ? "Loading records..." : "No flyer category found."}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {
                        showLoadMoreButton && !initLoadMore &&
                        <div className="text-center load-btn">
                            <button onClick={handleLoadMore} type="button" className="btn ">
                                Load More...
                            </button>
                        </div>
                    }

                    <DeleteFlyerModalPopup 
                        id=""
                        showModal={showDeleteFlyer}
                        handleClose={closeDeleteFlyerModal}
                        handleFlyerDelete={handleFlyerDelete}
                        idOfAlert={idOfAlert}
                    />

                    <AddEditFlyerModalPopup 
                        showModal={addEditFlyerModal}
                        handleClose={closeAddEditFlyerModal}
                        fetchData={fetchData}
                        flyerData={flyerData}
                        mcategoryName={mcategoryName}
                    />

                    {isLoading ? (<Loader />) : null}

                    {initLoadMore && <div className="text-center">
                        <Spinner animation="border" />;
                    </div>}

                </div>

                <SignInAsUser></SignInAsUser></div>

        </>
    );
};

export default DigitalFlyersIndex;
