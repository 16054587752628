import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../components/_services/common.services";
import listPdfIcon from "../../assets/img/alert-list-pdf.svg";
import listImageIcon from "../../assets/img/alert-list-image.svg";

const AlertViewModal = (prop) => {
    return (
        <>
        <Modal className="view-alert-modal" show={prop.showModal} onHide={prop.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Merch Alert Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <p className="viewAlertTd">Created Date:</p>
                    </div>
                    <div className="col-md-9">
                        <p>{formatDate(prop.viewData.createdAt, 'MDT')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <p className="viewAlertTd">Expire Date:</p>
                    </div>
                    <div className="col-md-9">
                        <p>{formatDate(prop.viewData.expiryDate, 'MD')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <p className="viewAlertTd">Message:</p>
                    </div>
                    <div className="col-md-9">
                        <p className="viewMessage">{prop.viewData.message}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <p className="viewAlertTd">Attachment:</p>
                    </div>
                    <div className="col-md-9">
                        <p>
                            {prop.viewData.attachmentType === '' ? (
                                'N/A'
                            ) : prop.viewData.attachmentType === 'application/pdf' ? (
                                <img onClick={(event)=>prop.handleFileDownload(event, prop.viewData._id, prop.viewData.attachment)} className="mListIconViewModal" src={listPdfIcon} alt="PDF Icon" />
                            ) : (
                                <img onClick={(event)=>prop.handleFileDownload(event, prop.viewData._id, prop.viewData.attachment)} className="mListIconViewModal" src={prop.viewData.filePreviewUrl} /* {listImageIcon} */ alt="Image Icon" />
                            )}
                        </p>
                    </div>
                </div>
            </div>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={prop.handleClose}>Looks Good</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default AlertViewModal;