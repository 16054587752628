import { useEffect } from "react";
import Navbar from "../../components/NavBar";
import VersionAddModal from "./version-add-modal";
import { Button } from "react-bootstrap";
import useModal from './model/version-history-model';
import VersionDeleteModal from "./version-delete-modal";
import Loader from "../../components/Loading/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInAsUser from "../../components/SignInAsUser";
const VersionHistory = () => {
  const { show, showDelete, versionList, idOfVersion, osValue, isLoading, isEdit, dataToEdit, showLoadMoreButton, handleLoadMore, fetchData, handleOnChangeForce, openAddVersionModal, closeAddVersionModal, openDeleteVersionModal, closeDeleteVersionModal, handleVersionHistoryDelete, handleOSChange, addEditVersion } = useModal();
  useEffect(()=>{
    fetchData(osValue, 1);
  },[])
  return (
    <>
      <Navbar />
      <div className="main-cover">
      <ToastContainer/>
      <div className="container">
        <div className="row mt-4 mb-4">
          <div className="col-md-6 category-title">App Version History</div>
          <div className="col-md-6 text-right">
            <div className="d-flex justify-content-end">
                
                <div className="showEntriesInner d-flex align-items-center">
                    <span className="showText">OS Type</span>
                    <select onChange={handleOSChange} className="mr-5 ml-2 form-control drop-one">
                    <option value="all">All</option>
                    <option value="Android">Android</option>
                    <option value="IOS">iOS</option>
                    </select>
                </div>

                <div>
                    <Button
                        className="upload-btn push-btn"
                        variant="primary"
                        onMouseDown={openAddVersionModal}
                    >
                        Push new update
                    </Button>
                </div>
            </div>
          </div>
        </div>
    
        <div className="row">
          <div className="col-sm-12 d-flex align-items-center justify-content-between mb-3">
            <div className="showEntries">
              <div className="showEntriesInner d-flex align-items-center">
              </div>
            </div>           
          </div>
        </div>
        <table
          //   style={getListStyle(snapshot.isDraggingOver)}
          id="tezst"
          className="table table-striped"
        >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Version</th>
              <th>OS</th>
              <th>Created Date</th>
              <th>Updated Date</th>
              <th>Description</th>
              <th>Forced</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
                versionList.length > 0 ?
                versionList.map((element,index)=>{
                    return(
                        <tr>
                            <td>{index+1}</td>
                            <td>{parseFloat(element.version)%1 == 0 ? parseFloat(element.version).toFixed(1):element.version}</td>
                            <td>{element.os == "IOS"? "iOS":element.os}</td>
                            <td>{new Date(element.createdAt).toLocaleString()}</td>
                            <td>{new Date(element.updatedAt).toLocaleString()}</td>
                            <td>{element.description}</td>
                            {/* <td>
                                <div className="custom-control custom-switch">
                                    <input key={element._id+index} name={element._id+index} checked={element.isForcefully? true: false} onChange={(e)=>handleOnChangeForce(e, element)} type="checkbox" className="custom-control-input" id={`category-custom-switch_64993d211250244503b1a5d8${element._id+index}`} />
                                    <label className="custom-control-label" for={`category-custom-switch_64993d211250244503b1a5d8${element._id+index}`}>
                                        <div className="custom-switch-text"></div>
                                    </label>
                                </div>
                            </td> */}
                            <td>{element.isForcefully?"Yes":"No"}</td>
                            <td>
                            <span onMouseDown={(event)=>openAddVersionModal(event, element)} className="action-icons mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                            </span>
                            <span onMouseDown={(event)=>openDeleteVersionModal(event, element._id)} className="action-icons ">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                            </span>
                            </td>
                        </tr> 
                    )
                })
                :<tr>{isLoading ? "Loading records...": "No record found related to version."}</tr>
            }
          </tbody>
        </table>
        {
            showLoadMoreButton &&
            <div className="text-center load-btn">
                <button onClick={handleLoadMore} type="button" className="btn ">
                    Load More...
                </button>
            </div>
        }  
        <VersionAddModal
          id=""
          isEdit={dataToEdit.isEdit}
          showModal={dataToEdit.show}
          handleClose={closeAddVersionModal}
          dataToEdit={dataToEdit.data}
          addEditVersion={addEditVersion}
        />
        <VersionDeleteModal 
            id=""
            showModal={showDelete}
            handleClose={closeDeleteVersionModal}
            handleVersionHistoryDelete={handleVersionHistoryDelete}
            idOfVersion={idOfVersion}
        />
        {isLoading?
            (<Loader />):null
        }
      </div>
      <SignInAsUser></SignInAsUser></div>
    </>
  );
};

export default VersionHistory;
