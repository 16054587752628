import { useEffect, useState } from "react";
import { useHttpClient } from "../../hooks/HttpHook";
import Navbar from "../../components/NavBar";
import useModal from './model/alerts-model';
import Loader from "../../components/Loading/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EndPoint from "../../api/Endpoint";
import SignInAsUser from "../../components/SignInAsUser";
import { formatDate } from "../../components/_services/common.services";
import AlertDeleteModal from "./alert-delete-modal";
import AlertViewModal from "./alert-view-modal";
import SendNewAlertModal from "./send-new-alert-modal";
import { Button } from "react-bootstrap";
import listPdfIcon from "../../assets/img/alert-list-pdf.svg";
import listImageIcon from "../../assets/img/alert-list-image.svg";

const AlertsIndex = () => {

  const { modulesList, viewData, handleLoadMore, showLoadMoreButton, isLoading, fetchData, idOfAlert, showDeleteAlert, openDeleteAlertModal, closeDeleteAlertModal, handleAlertDelete, showViewAlert, openViewAlertModal, closeViewAlertModal, openNewAlertModal, closeNewAlertModal, showNewAlert, handleFileDownload } = useModal();
  
  useEffect(() => {
    fetchData(1);
  }, []);
  
  return (
    <>
      <Navbar />
      <ToastContainer/>
      <div className="main-cover">
      <div className="container">
        <div className="row mt-0 mb-4 category-top category-top-flyer">
          <div className="col-md-6 category-title">MERCH ALERTS</div>

          <span className="col-lg-6 text-right">
            <Button className="send-new-alert-button" variant="primary" onClick={openNewAlertModal} >
              SEND NEW ALERT
            </Button>
          </span>

        </div>

        <table id="tezst" className="table table-striped">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Create Date</th>
              <th>Expiry Date</th>
              <th>Description</th>
              <th>Attachment</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
                modulesList.length > 0 ?
                modulesList.map((element,index)=>{
                    return(
                        <tr>
                            <td>{index+1}</td>
                            <td>{formatDate(element.createdAt, 'MDT')}</td>
                            <td>{formatDate(element.expiryDate, 'MD')}</td>
                            <td title={element.message}>{element.message.length > 50 ? `${element.message.slice(0, 50)}...` : element.message}</td>
                            <td>
                              {element.attachmentType === '' ? (
                                  'N/A'
                              ) : element.attachmentType === 'application/pdf' ? (
                                  <img onClick={(event)=>handleFileDownload(event, element._id, element.attachment)} className="mListIcon" src={listPdfIcon} alt="PDF Icon" />
                              ) : (
                                  <img onClick={(event)=>handleFileDownload(event, element._id, element.attachment)} className="mListIcon" src={element.filePreviewUrl} /* {listImageIcon} */ alt="Image Icon" />
                              )}
                          </td>
                            <td>
                              <span onMouseDown={(event)=>openViewAlertModal(event, element)} className="action-icons mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height="1em"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/></svg>
                              </span>
                              <span onMouseDown={(event)=>openDeleteAlertModal(event, element._id)} className="action-icons ">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                              </span>
                            </td>
                        </tr> 
                    )
                })
                :<tr><td colSpan="6">{isLoading ? "Loading records..." : "No record found."}</td></tr>
            }
          </tbody>
        </table>

        {
          showLoadMoreButton &&
          <div className="text-center load-btn">
            <button onClick={handleLoadMore} type="button" className="btn ">
              Load More...
            </button>
          </div>
        }

        <AlertDeleteModal 
            id=""
            showModal={showDeleteAlert}
            handleClose={closeDeleteAlertModal}
            handleAlertDelete={handleAlertDelete}
            idOfAlert={idOfAlert}
        />

        <AlertViewModal 
            id=""
            showModal={showViewAlert}
            viewData={viewData}
            handleClose={closeViewAlertModal}
            idOfAlert={idOfAlert}
            handleFileDownload={handleFileDownload}
        />

        <SendNewAlertModal 
            showModal={showNewAlert}
            handleClose={closeNewAlertModal}
            fetchData={fetchData}
        />

        {isLoading ? (<Loader />) : null}

      </div>

      <SignInAsUser></SignInAsUser></div>

    </>
  );
};

export default AlertsIndex;
