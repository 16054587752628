const Constants = {
    BASE_URL:process.env.REACT_APP_BASE_URL,
    FRONT_BASE_URL:process.env.REACT_APP_FRONT_BASE_URL,
    REACT_APP_FRONT_APPLICATION_BASE_URL:process.env.REACT_APP_FRONT_APPLICATION_BASE_URL,
    FRONT_BASE_PATH:'',
    IMAGE_URL:'',
    PAGINATION:10,
    FLYER_PAGINATION:100,
    PDF_FILE_SIZE_LIMIT:process.env.REACT_APP_PDF_FILE_SIZE_LIMIT
}
export default Constants;
