import React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from 'react-router-dom';
export const RedirectPrivateRoute = ({role, Component}) => {

    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    let currentUser = localStorage.getItem('token');
    
    useEffect(()=>{console.log('admin side')
        currentUser = localStorage.getItem('token');
    },[isAuth]);

    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to='/admin/login' />
    }
    const roleToken = localStorage.getItem('token');
    // console.log("Role of user", role, currentUser, roleToken);
    // check if route is restricted by role
    // if (role && role.indexOf(roleToken.role) === -1) {
    //     // role not authorised so redirect to home page
    //     return <Navigate to='/' />
    // }
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return currentUser ? <Component /> : <Navigate to="/admin/login" />;
}