import React from "react";
import { Link } from 'react-router-dom';
import ImageTag from "../../components/ImageTag";
import PriceOneIcon from "../../assets/img/price-one.png"
import DigitalFlyerIcon from "../../assets/img/boost-flyers.svg"
import Constants from "../../api/Constants";

import SignInAsUser from "../../components/SignInAsUser";

import axios from 'axios';
import { useState, useEffect } from 'react';

const MainIndex = () => {

    const [token, setToken] = useState('');

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
        setToken(storedToken);
        }
    }, []);

    return (
        <>
        <section className="page-inner-cover position-relative">
            <div className="container">
            <section className="price-of-new-inner new-panel-work">
                <div className="price-main-box">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="price-main-outer d-flex justify-content-center">
                                <div className="price-boxs">    
                                    <Link className="price-main-outer-link-box" to={`${Constants.FRONT_BASE_PATH}/category`}>
                                        <div className="price-select-one">
                                            <div>
                                                <ImageTag src={PriceOneIcon} />
                                            </div>
                                            <div className="price-title">Merch Grids</div>
                                        </div>
                                    </Link>
                                </div>
                                
                                <div className="price-boxs">    
                                    <Link className="price-main-outer-link-box digital-box-link" to={`${Constants.FRONT_BASE_PATH}/digital-flyers`}>
                                        <div className="price-select-one digital-box">
                                            <div>
                                                <ImageTag src={DigitalFlyerIcon}  className="digital-icon-img"/>
                                            </div>
                                            <div className="price-title">Digital Flyers</div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>

            <SignInAsUser></SignInAsUser>

        </section>
        </>
    );
};
export default MainIndex;