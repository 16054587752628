import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf";
import pdfIcon from "../../assets/img/file-plus-regular.svg";
import imageIcon from "../../assets/img/file-plus-regular.svg";
import CloseIcon from "../../assets/img/modal-close-icon.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EndPoint from "../../api/Endpoint";
import { useHttpClient } from "../../hooks/HttpHook";
import axios from "axios";
import Loader from "../../components/Loading/Loader";
import { getTomorrowDate } from "../../components/_services/common.services";

const SendNewAlertModal = (props) => {
    const [attachment, setAttachment] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [message, setMessage] = useState("");
    const [attachmentPreview, setAttachmentPreview] = useState(null);
    const [formValid, setFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(()=>{
        setMessage("");
        setExpiryDate("");
        setAttachment("");
        setAttachmentPreview("");
        setFormValid(false);
    }, [props.showModal])

    const handleChange = (event) => {
        const file = event.target.files[0];
        setAttachment(file);

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setAttachmentPreview(reader.result);
            };
        } else if (file && file.type === "application/pdf") {
            setAttachmentPreview(file);
        } else if (file && (file.type !== "application/pdf" || !file.type.startsWith("image"))) {
            toast.error('Please select only pdf or image type file.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            // Handle unsupported file types
            console.warn("Unsupported file type:");
        }
        event.target.value = null;
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleClosePreview = (event) => {
        event.preventDefault();
        setAttachment("");
        setAttachmentPreview(null);
    };

    const handleSendAlert = async () => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("message", message);
            formData.append("expiryDate", expiryDate);
            if (attachment) {
                formData.append("pdf", attachment);
            }

            const token = localStorage.getItem("token");
            const response = await axios.post(EndPoint.SEND_NEW_ALERT, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status !== 200) {
                throw new Error("Failed to send alert");
            }

            // Clear form fields after successful send
            setMessage("");
            setExpiryDate("");
            setAttachment("");
            setAttachmentPreview(null);

            toast.success("Alert sent successfully");
            setIsLoading(false);
            props.handleClose();
            props.fetchData(1, true);
        } catch (error) {
            setIsLoading(false);
            console.error("Error sending alert:", error);
            if(error.response.status == 403){
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location.href= `${EndPoint.FRONT_LOGIN_URL}`;;
            }
            toast.error("Failed to send alert");
        }
    };

    const handleFormValidation = () => {
        if (message /* && expiryDate && attachment */) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    };

    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>SEND NEW ALERT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group className="col-12 col-sm-12 col-md-12 alertArea">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    maxLength={500}
                                    value={message}
                                    onChange={handleMessageChange}
                                    required
                                    onKeyUp={handleFormValidation}
                                    placeholder="Enter Alert Message"
                                />
                                <Form.Text className="text-muted">
                                    {message.length}/500 characters
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>Upload file </Form.Label>
                            <label className="pdf-modal-dragbox alert-image-box" htmlFor="pdf">
                                <Form.Control type="file" id="pdf" onChange={(e) => handleChange(e)} accept=".pdf, image/*" />
                               {attachmentPreview&&<div className="pdf-close" onClick={handleClosePreview}><img src={CloseIcon} alt="Close Icon" /></div>} 
                                <Row>
                                    <Col>
                                        {attachmentPreview && (
                                            typeof attachmentPreview === "string" ? (
                                                <img src={attachmentPreview} alt="Attachment Preview" />
                                            ) : (
                                                <Document
                                                    file={attachmentPreview}
                                                    noData={<div className="pdf-drag-drop-text"><div className="pdf-drag-drop-icon"><img src={pdfIcon} alt="PDF Icon" /></div>Drag & Drop<br />or <span>browse</span></div>}
                                                >
                                                    <Page pageNumber={1} width={500} height={263} />
                                                </Document>
                                            )
                                        )}
                                        {!attachmentPreview && (
                                            <div className="pdf-drag-drop-text">
                                                <div className="pdf-drag-drop-icon">
                                                    <img src={attachment?.type === "application/pdf" ? pdfIcon : imageIcon} alt="File Icon" />
                                                </div>
                                                Drag & Drop or <span>Browse</span>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </label>
                            <Form.Text className="text-muted">
                                Allowed file types : PDF, JPG, PNG and JPEG
                            </Form.Text>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group className="col-12 col-sm-12 col-md-12">
                                <Form.Label>Expiry Date</Form.Label>
                                <DatePicker
                                    selected={expiryDate}
                                    onChange={(date) => setExpiryDate(date)}
                                    minDate={getTomorrowDate()}
                                    className="form-control"
                                    onBlur={handleFormValidation}
                                    placeholderText="Select expiry date"
                                    onKeyDown={(e) => e.preventDefault()}
                                    onFocus={(e) => e.target.blur()}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSendAlert} disabled={!formValid}>Send</Button>
                    <Button variant="secondary" onClick={props.handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            {isLoading ? (<Loader />) : null}
        </>
    );
};

export default SendNewAlertModal;