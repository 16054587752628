import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import EndPoint from "../../api/Endpoint";

const VersionAddModal = (props) => {
  const [formData, setFormData] = useState({
    os:'Android',
    version:'',
    link:'',
    description:'',
    force: false
  });
  const [formerrors, setFormErrors] = useState({});
  const prevEditRef = useRef();
  const prevIdRef = useRef();
  const prevShowModal = useRef();
  const onChangeHandler = (event) => {
    if(event.target.value.length <= 100 && event.target.name != 'isForcefully'){
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
    else if(event.target.name == 'isForcefully'){
      console.log("##### onChangeHandler #####", event.target.checked);
      setFormData({ ...formData, [event.target.name]: event.target.checked });
    }
  };
  useEffect(()=>{
    if(props.isEdit){
      // if((prevEditRef.current != props.isEdit || !prevEditRef.current) && props.showModal){
        //   setFormErrors({});
        //   setFormData(props.dataToEdit);
        // }else 
      prevEditRef.current = props.isEdit; 
      if((prevIdRef.current != props.dataToEdit._id) || (prevShowModal.current != props.showModal)){
        prevIdRef.current = props.dataToEdit._id;
        prevShowModal.current = props.showModal;
        prevEditRef.current = props.isEdit;
        setFormErrors({});
        setFormData(props.dataToEdit);
      }
    }else{
      if(prevShowModal.current != props.showModal){
        prevShowModal.current = props.showModal;
        prevEditRef.current = props.isEdit;
        prevIdRef.current = '';
        setFormErrors({});
        if(!props.showModal){
          return;
        }
        setFormData({ os:'Android', version:'', link:'', description:'' });  
      }
    }
  },[props])

  const handleAddEdit = () =>{
    if(!validate()){
      console.log("########## validate() handleAddEdit ##############", validate());
      return;
    }
    props.addEditVersion(formData, props.isEdit);
  }

  const validateInput = (event) => {
    let { name, value } = event.target;
    setFormErrors(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        
        case "version":
          
          if (!value) {
            stateObj[name] = "Version is required.";
          }else if (!/^\d\.?\d{1,2}$/.test(value)){
            stateObj[name] = "Version should have min one and max two digit(s) after decimal point.";
          }
          break;
        case "link":
          if (!value || value.trim() =="") {
            stateObj[name] = "Link is required.";
          }
          break;

        case "description":
          if (!value || value.trim() =="") {
            stateObj[name] = "Description is required.";
          } else if (value.length > 50) {
            stateObj[name] = "Description can't be greater than 50 characters.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }

  const validate = () => {
    let errors = {};
    //password field
    if (!formData.version) {
      errors.version = "version is required.";
    }else if (!/^\d\.?\d{1,2}$/.test(formData.version) && !props.isEdit){
      errors.version  = "Version should have min one and max two digit(s) after decimal point.";
    }
    if(!formData.link || formData.link.trim() =="") {
      errors.link = "Link is required.";
    }

    if(!formData.description || formData.description.trim() =="") {
      errors.description = "Description is required.";
    }else if (formData.description.length > 50) {
      errors.description = "Description can't be greater than 50 characters.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal show={props.showModal} onHide={props.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>{props.isEdit ? "Edit Mobile App Version": "Push new update"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <form>
                <div className="form-group row">
                    <label for="staticEmail" className="col-sm-2 col-form-label form-label">OS Type</label>
                    <div className="col-sm-10">
                        <select disabled={props.isEdit} name="os" value={formData.os} onChange={onChangeHandler} className="form-control">
                            <option value="Android">Android</option>
                            <option value="IOS">iOS</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label for="inputPassword" className="col-sm-2 col-form-label form-label">Version</label>
                    <div className="col-sm-10">
                    <input disabled={props.isEdit} name="version" value={formData.version} onChange={onChangeHandler} type="text" className="form-control" onBlur={validateInput}/>
                    {formerrors.version && (
                      <p className="text-warning">{formerrors.version}</p>
                    )}
                    </div>
                </div>
                <div className="form-group row">
                    <label for="inputPassword" className="col-sm-2 col-form-label form-label">Link</label>
                    <div className="col-sm-10">
                    <input disabled={props.isEdit} name="link" value={formData.link} type="text" onChange={onChangeHandler} className="form-control" onBlur={validateInput}/>
                    {formerrors.link && (
                      <p className="text-warning">{formerrors.link}</p>
                    )}
                    </div>
                </div>
                <div className="form-group row">
                    <label for="inputPassword" className="col-sm-2 col-form-label form-label">Description</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" name="description" onChange={onChangeHandler} value={formData.description} onBlur={validateInput}/>
                      {formerrors.description && (
                        <p className="text-warning">{formerrors.description}</p>
                      )}
                    </div>
                </div>
                <div className="form-group row">
                    <label for="inputPassword" className="col-sm-2 col-form-label form-label">Forced</label>
                    <div className="col-sm-10">
                        <td>
                            <div className="custom-control custom-switch">
                                <input name="isForcefully" checked={formData.isForcefully? true: false} onChange={onChangeHandler} type="checkbox" className="custom-control-input" id="category-custom-switch_64993d211250244503b1a5d8" />
                                <label className="custom-control-label" for="category-custom-switch_64993d211250244503b1a5d8">
                                    <div className="custom-switch-text"></div>
                                </label>
                            </div>
                        </td>
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="Edit-btn-version-secondary" variant="secondary" onClick={handleAddEdit}>
            {props.isEdit ?"Edit":"Add"}
          </Button>
          <Button className="Edit-btn-version-secondary" variant="primary" onClick={props.handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default VersionAddModal;
