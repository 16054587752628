import Constants from "./Constants";

const EndPoint = {
    CATEGORY_URL:`${Constants.BASE_URL}category`,
    CATEGORY_URL_All:`${Constants.BASE_URL}category/all`,
    CREATE_CATEGORY_URL:`${Constants.BASE_URL}category/create`,
    UPDATE_CATEGORY_URL:`${Constants.BASE_URL}category/update`,
    UPDATE_CATEGORY_ORDER_URL:`${Constants.BASE_URL}category/update-order`,
    SUBCATEGORY_URL:`${Constants.BASE_URL}category/view/`,
    UPDATE_CATEGORY_PDF_URL:`${Constants.BASE_URL}category/update-pdf/`,
    CHECK_UPDATE_CATEGORY_PDF_URL:`${Constants.BASE_URL}category/check-update-pdf/`,

    DELETE_CATEGORY_PDF_URL:`${Constants.BASE_URL}category/delete-pdf/`,
    VIEW_PDF_URL:`${Constants.BASE_URL}category/get-pdf/`,
    
    PRODUCTS_URL:`${Constants.BASE_URL}products/cat/`,
    PRODUCT_DETAIL_URL:`${Constants.BASE_URL}products/`,
    REGISTER_URL:`${Constants.BASE_URL}auth/register`,
    LOGIN_URL:`${Constants.BASE_URL}user/login`,
    FORGOT_PASSWORD_URL:`${Constants.BASE_URL}user/forgot-password`,
    RESET_PASSWORD_URL:`${Constants.BASE_URL}user/reset-password`,
    FRONT_LOGIN_URL:`${Constants.FRONT_BASE_URL}/login`,

    // Front routes
    CHANGE_PASSWORD:`${Constants.FRONT_BASE_URL}/user/change-password`,
    USERCATEGORYPDF_URL:`${Constants.BASE_URL}category/get-pdf/`,
    LOGIN_API_URL:`${Constants.BASE_URL}user-front/login`,
    SIGNUP_URL:`${Constants.BASE_URL}user-front/signup`,   
    FRONT_VERIFY_EMAIL_URL:`${Constants.FRONT_BASE_URL}/user/verify-email`,
    VERIFY_EMAIL_API:`${Constants.BASE_URL}user-front/verify-email`,
    DEACTIVATE_EMAIL_API:`${Constants.BASE_URL}user-front/de-activate-user`,
    FRONT_USER_LOGIN_URL:`${Constants.FRONT_BASE_URL}/user/login`,
    BASE_URL_CATEGORY_FRONT_API:`${Constants.BASE_URL}category-front/`,
    LOCATION_FRONT_API:`${Constants.BASE_URL}location/search-location`,
    LOCATION_LISTING_FRONT_API:`${Constants.BASE_URL}location/search-listing`,
    FORGOT_PASSWORD_URL_FRONT:`${Constants.BASE_URL}user-front/forgot-password`,
    RESET_PASSWORD_URL_FRONT:`${Constants.BASE_URL}user-front/reset-password`,
    Change_PASSWORD_URL_API: `${Constants.BASE_URL}user-front/change-password`,
    ALL_USERS_API: `${Constants.BASE_URL}user/all-users`,
    DELETE_USERS_API: `${Constants.BASE_URL}user/delete-user`,
    GET_ALL_USERS_SEARCH_API: `${Constants.BASE_URL}user/get-all-user-search`,
    EXPORT_CSV_USERS_API: `${Constants.BASE_URL}user/export-all-users`,
    LOCATION_FILE_UPLOAD_API:`${Constants.BASE_URL}location/upload-file`,
    INDEX_JSON_CATEGORY_FRONT_API:`${Constants.BASE_URL}category-front/get-index-json`,
    MULTIPLE_IMAGE_CATEGORY_FRONT_API:`${Constants.BASE_URL}category-front/get-multiple-images`,
    SINGLE_IMAGE_CATEGORY_FRONT_API:`${Constants.BASE_URL}category-front/get-single-images`,
    LISTING_OF_ALL_FRONT_API:`${Constants.BASE_URL}v2/version-control/listing-of-existing-versions`,
    DELETE_OF_VERSION_FRONT_API:`${Constants.BASE_URL}v2/version-control/delete-a-version/`,
    ADD_VERSION_FRONT_API:`${Constants.BASE_URL}v2/version-control/create-new-version`,
    EDIT_VERSION_FRONT_API:`${Constants.BASE_URL}v2/version-control/update-existing-version`,

    LISTING_OF_MODULES:`${Constants.BASE_URL}v2/access-modules/listing-of-modules`,
    UPDATE_VALUE_OF_MODULE:`${Constants.BASE_URL}v2/access-modules/update-value-of-module`,
    LOGOUT_API_URL:`${Constants.BASE_URL}user/logout`,
    
    ALERT_LIST:`${Constants.BASE_URL}v2/alerts/alert-list`,
    GET_ALERT:`${Constants.BASE_URL}v2/alerts/get-alert/`,
    DELETE_ALERT:`${Constants.BASE_URL}v2/alerts/delete-alert/`,
    SEND_NEW_ALERT:`${Constants.BASE_URL}v2/alerts/send-new-alert`,
    GET_ALERT_FILE:`${Constants.BASE_URL}v2/alerts/get-alert-file/`,
    
    DIGITAL_FLYER_LIST:`${Constants.BASE_URL}v2/digital-flyers/digital-flyer-list`,
    ADD_DIGITAL_FLYER:`${Constants.BASE_URL}v2/digital-flyers/add-digital-flyer`,
    EDIT_DIGITAL_FLYER:`${Constants.BASE_URL}v2/digital-flyers/edit-digital-flyer`,
    DELETE_FLYER:`${Constants.BASE_URL}v2/digital-flyers/delete-digital-flyer/`,
    CHANGE_FLYER_STATUS:`${Constants.BASE_URL}v2/digital-flyers/change-flyer-status/`,    
    DIGITAL_FLYER_FILES_INITIAL_LOAD_DATA:`${Constants.BASE_URL}v2/digital-flyers/digital-flyer-files-initial-load-data`,
    DIGITAL_FLYER_FILES_LIST_WITH_FILTER:`${Constants.BASE_URL}v2/digital-flyers/digital-flyer-files-list-with-filter`,
    DOWNLOAD_DIGITAL_FLYER_FILES:`${Constants.BASE_URL}v2/digital-flyers/download-digital-flyer-files`,
}

export default EndPoint;