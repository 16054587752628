import './App.css';
import './assets/css/front.css';
import {BrowserRouter,Routes,Route,Navigate} from 'react-router-dom'

import LoginPage from "./pages/LoginPage";
import CategoryPage from "./pages/CategoryPage";
import ResetPassword from "./components/Login/resetPassword";
import Constants from "./api/Constants";
import { useEffect, useState } from "react";
//Category 
import Category from "./components/CategoryList";
import UserManagementList from "./components/UserManagement/index";

// User section
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import VersionHistory from "./components/VersionControl/version-history";

import AccessModules from "./components/AccessModules/access-modules";
import AlertsModule from "./components/Alerts/alerts-index";
import DigitalFlyersModule from "./components/DigitalFlyers/df-main-index";
import DigitalFlyerFilesModule from "./components/DigitalFlyers/df-files-index";

import HomePage from "./pages/HomePage";
import AdminAuthenticate from "./components/AdminAuthenticate";
import { RedirectPrivateRoute } from './components/PrivateRoute/RedirectPrivateRoute';

import { useSelector, useDispatch } from "react-redux";

function App() {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => localStorage.getItem('token') !== null
  );
  useEffect(()=>{
    setIsLoggedIn(localStorage.getItem('token'));
  },[localStorage.getItem('token'), isAuth]);
  return (
    <BrowserRouter >
      <Routes> 
      
      <Route path={`${Constants.FRONT_BASE_PATH}`} element={<RedirectPrivateRoute Component={HomePage}/>} />

      {/* <Route path={`${Constants.FRONT_BASE_PATH}`} element={isLoggedIn?<HomePage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/admin/login`} />} />       */}
      <Route path={`${Constants.FRONT_BASE_PATH}`} element={!isLoggedIn?<LoginPage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/`} />} />    
      <Route path={`${Constants.FRONT_BASE_PATH}/admin/authenticate/:token`} element={<AdminAuthenticate />} /> 
      <Route path={`${Constants.FRONT_BASE_PATH}/admin/login`} element={!isLoggedIn?<LoginPage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/`} />} />    
      <Route path={`${Constants.FRONT_BASE_PATH}/user-management-list`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={UserManagementList} role={"ADMIN"}/>} />
      <Route path={`${Constants.FRONT_BASE_PATH}/reset-password`} element={<ResetPassword />} />
      <Route path={`${Constants.FRONT_BASE_PATH}/category/:categoryId`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={CategoryPage} role={"ADMIN"}/>} /> 

      <Route path={`${Constants.FRONT_BASE_PATH}/category`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={Category} role={"ADMIN"}/>} />  {/* element={isLoggedIn?<Category />:<Navigate to='/login'/>} */}

      <Route path={`${Constants.FRONT_BASE_PATH}/version-history`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={VersionHistory} role={"ADMIN"}/>} /> 

      <Route path={`${Constants.FRONT_BASE_PATH}/access-modules`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={AccessModules} role={"ADMIN"}/>} /> 
      <Route path={`${Constants.FRONT_BASE_PATH}/alerts`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={AlertsModule} role={"ADMIN"}/>} />

      <Route path={`${Constants.FRONT_BASE_PATH}/digital-flyers`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={DigitalFlyersModule} role={"ADMIN"}/>} /> 
      <Route path={`${Constants.FRONT_BASE_PATH}/digital-flyers/:flyerId`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={DigitalFlyerFilesModule} role={"ADMIN"}/>} /> 

        <Route
            path="*"
            element={<Navigate to={`${Constants.FRONT_BASE_PATH}/`} replace />}
        />
      </Routes>
  </BrowserRouter>
  );
}

export default App;