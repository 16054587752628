import { useEffect, useState } from "react";
import { useHttpClient } from "../../hooks/HttpHook";
import Navbar from "../../components/NavBar";
import useModal from './model/access-modules-model';
import Loader from "../../components/Loading/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EndPoint from "../../api/Endpoint";
import SignInAsUser from "../../components/SignInAsUser";

const AccessModules = () => {
  
  const { sendRequest } = useHttpClient();

  const { modulesList, osValue, isLoading, fetchData } = useModal();
  useEffect(()=>{
    fetchData();
  },[]);

  const updateModuleEndPointAPI = EndPoint.UPDATE_VALUE_OF_MODULE;
  const [checked, setChecked] = useState(false);
  
  const onChangeHandler = async (event, moduleId) => {

    const newValue = event.target.checked;
    // setChecked(newValue); // Update local state immediately

    const body = {
      id: moduleId,
      showToUser: newValue
    };

    try {
      // Make an API call to update the value in the database
      const response = await sendRequest(
        updateModuleEndPointAPI,
        "POST",
        body
        // JSON.stringify(body)
      );
      if (!response.data) {
        throw new Error('Failed to update value in the database');
      }
      
      // on success fetch again 
      fetchData();

    } catch (error) {
      console.error('Error updating value in the database:', error);
      setChecked((prevChecked) => !prevChecked);
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer/>
      <div className="main-cover">
      <div className="container">
        <div className="row mt-4 mb-4">
          <div className="col-md-6 category-title">Modules Permissions</div>
        </div>
    
        <div className="row">
          <div className="col-sm-12 d-flex align-items-center justify-content-between mb-3">
            <div className="showEntries">
              <div className="showEntriesInner d-flex align-items-center">
              </div>
            </div>           
          </div>
        </div>
        <table
          id="tezst"
          className="table table-striped"
        >
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Module Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
                modulesList.length > 0 ?
                modulesList.map((element,index)=>{
                    return(
                        <tr>
                            <td>{index+1}</td>
                            <td>{element.moduleName}</td>
                            <td>
                              <div className="custom-control custom-switch">
                                  {/* {setChecked(element.showToUser ? true : false)} */}
                                  <input name="showToUser" checked={element.showToUser ? true : false} onChange={(event) => onChangeHandler(event, element._id)} type="checkbox" className="custom-control-input" id={`category-custom-switch_${element._id}`} />
                                  <label className="custom-control-label" htmlFor={`category-custom-switch_${element._id}`}>
                                      <div className="custom-switch-text"></div>
                                  </label>
                              </div>
                            </td>
                        </tr> 
                    )
                })
                :<tr><td colSpan="3">{isLoading ? "Loading records..." : "No record found."}</td></tr>
            }
          </tbody>
        </table>
        {isLoading?
            (<Loader />):null
        }
      </div>
      <SignInAsUser></SignInAsUser></div>
    </>
  );
};

export default AccessModules;
