// Date format in listing
export const formatDate = (dateString, formatDateTime) => {
    if(dateString !== null && dateString !== undefined) {
        
        const date = new Date(dateString);
        let options;
        if(formatDateTime === 'MDT') {
            options = { 
                day: 'numeric', 
                month: 'long', 
                year: 'numeric',
                hour: 'numeric', 
                minute: 'numeric', 
                second: 'numeric' 
            };
        } else {
            options = { 
                day: 'numeric', 
                month: 'long', 
                year: 'numeric'
            };
        }

        // Format the date
        let formattedDate = date.toLocaleString('en-GB', options);

        if(formatDateTime === 'MDT') {
            // Split the date into parts
            const [datePart, timePart] = formattedDate.split(' at ');

            // Add comma after the month
            const commaIndex = datePart.lastIndexOf(' ');
            formattedDate = datePart.slice(0, commaIndex) + ',' + datePart.slice(commaIndex) + ' ' + timePart;
        }

        return formattedDate;
    } else {
        return 'N/A';
    }
};

// Function to get tomorrow's date
export const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Increment day by 1
    return tomorrow;
};