import React, { useEffect, useState } from "react";
import Navbar from "../../components/NavBar";
import { redirect } from "react-router-dom";
import Constants from "../../api/Constants";
import jwt_decode from "jwt-decode";
import MainIndex from "./mainIndex";
import UserNavbar from "../../components/NavBar/userNav";
import UserMainIndex from "./user-main-index";
const HomePage = () => {
  const [userInfo, setUserInfo] = useState({
    userId: "",
    email: "",
    role: "",
  });
  useEffect(() => {
    if(localStorage.getItem('token') == null){
      redirect(`${Constants.FRONT_BASE_URL}/login`);
    }else{
     fetchJsonWebToken();
    }
  }, []);

  const fetchJsonWebToken = async() =>{
    const tokenData = localStorage.getItem('token');
      // Decode the JWT token
      const jwtData = jwt_decode(tokenData);
      if(jwtData){
        setUserInfo({
          email: jwtData.email,
          role: jwtData.role,
          userId: jwtData.userId
        })
      }
  }
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <>
        <Navbar />
        <MainIndex/>
      </>
      {/* {
        userInfo['role'] === "FRONT"?
        <>
          <UserNavbar />
          <section className="page-inner-cover">
            <div className="container">
              <UserMainIndex />
            </div>
          </section>
        </>
        :
        <>
          <Navbar />
          <MainIndex/>
        </>
      } */}
      <footer className="footer-section">
        <div className="container">
            © {year} DISH Wireless L.L.C. All rights reserved.
        </div>
    </footer>
    </>
  );
};
export default HomePage;
