import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EndPoint from "../../api/Endpoint";
import { useHttpClient } from "../../hooks/HttpHook";
import Dropdown from 'react-bootstrap/Dropdown';
import headerLogoNew from "../../assets/img/header-logo-new.png";
import ImageTag from "../ImageTag";
import { slice } from 'lodash';
import Navbar from "../NavBar";
import Loading from "../Loading";
import Constants from "../../api/Constants";
import DeleteUserModal from "./deleteModal";
import { Button } from 'react-bootstrap';
import Loader from "../Loading/Loader";
import SignInAsUser from "../../components/SignInAsUser";
const UserManagementList = (props) => {
    const [categories, setCategories] = useState([]);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(10)
    const [initialPosts, setInitialPosts]= useState([]);
    const [responseObj, setResponce] = useState({
        show: false,
        message: "",
        type: "error",
    });
    const grid = 8;
    const [selectedEmail, setSelectedEmail] = useState("");
    const [delete_modal_show, setDeleteModalShow] = useState(false);
    const [page, setPage] = useState(1);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [isLoader, setIsLoading] = useState(false);
    const [inputVal, setInputVal] = useState("");
    const [searchKeyEnabled, setSearchKeyEnabled] = useState(false);
    const handleUserDeleteClose = () => {
        setDeleteModalShow(false);
        setSelectedEmail("");
    };

    const loadMore = () => {
        const nextPage = page+1;
        setPage(nextPage);
        fetchData(nextPage);
    }

    const handleMouseDownDelete = (e, item) =>{
        setDeleteModalShow(true);
        setSelectedEmail(item.email);
    }

    useEffect(() => {
        fetchData(1);
    }, []);

    const getListStyle = () => ({
        padding: grid,
    });

    const fetchData = (pageArg, ALL_USERS_API) => {
        let body ={
            page: pageArg
        };
        let apiToCall = EndPoint.ALL_USERS_API;
        if(inputVal.length >=3 && searchKeyEnabled){
            body.searchKey = inputVal;  
            apiToCall = EndPoint.GET_ALL_USERS_SEARCH_API;
        }
        apiCall(pageArg, apiToCall, body);
    };

    const handleUserDelete = (e, email) => {
        handleUserDeleteClose();
        let body ={
            page: page,
            email: email
        };
        apiCall(page, EndPoint.DELETE_USERS_API, body);
    }

    const onKeyDownHandler = (e) =>{
        if(e.key === "Enter" && e.target.value.length >=3){
            const searchString = e.target.value;
            setSearchKeyEnabled(true);
            let body ={
                page: 1,
                searchKey: searchString
            };
            setPage(1);
            apiCall(page, EndPoint.GET_ALL_USERS_SEARCH_API, body);    
        }
    }
    const onChangeHandler = (e) =>{
        setInputVal(e.target.value);
        if(e.target.value === ""){
            setSearchKeyEnabled(false);
            let body = {
                page: 1
            };
            let apiToCall = EndPoint.ALL_USERS_API;
            apiCall(1, apiToCall, body);
        }
    }
    
    const apiCall = async(pageArg, apiPath, body) =>{
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                apiPath,
                "POST",
                body
            );
            setIsLoading(false);
            const lengthData = responseData.users.length;
            const totalCountVar = responseData.totalNumOfUsers;
            if(lengthData >= totalCountVar){
                setShowLoadMoreButton(false);
                if(inputVal.length >=3 && searchKeyEnabled){
                    setPage(1);
                    setSearchKeyEnabled(false);
                }
            }else{
                setShowLoadMoreButton(true);
            }
            const initialDataArr = responseData.users; //slice(, 0, 10);
            setInitialPosts(initialDataArr);
            setCategories(responseData.users);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }

    const handleExportCSV = async () =>{
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.EXPORT_CSV_USERS_API
            );
            setIsLoading(false);
            console.log("###$$$$$$$$$$ responseData ##########", responseData);
            let data = new Blob([responseData.csvData], {type: 'text/csv'});
            let csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'Users.csv');
            tempLink.click();
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }

    return (
        <>
            <Navbar/><div className="main-cover">
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btn-and-export-cover d-flex justify-content-end mt-3">
                                <div className="search-inner d-flex"> 
                                    <div className="wrapper">
                                        <input onKeyDown={onKeyDownHandler} onChange={onChangeHandler} value={inputVal} placeholder="Search by name or email" className="input-search" />
                                    </div>
                                </div>
                                <div className="export-btn-cover">
                                    <button onMouseDown={handleExportCSV} className="export-btn-inner">EXPORT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-categories admin-title">User Management</div>
                            <div className="pdf-inner-outer">
                            <table
                                style={getListStyle()}
                                id="tezst" className="table table-striped table-inner-view">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }} scope="col">
                                                SNo.
                                            </th>
                                            <th style={{ width: "20%" }} scope="col">
                                                Name
                                            </th>
                                            <th style={{ width: "30%" }} scope="col">
                                                Email
                                            </th>
                                            <th style={{ width: "15%" }} scope="col">
                                                Joined Date
                                            </th>
                                            <th style={{ width: "10%" }} scope="col">
                                                Status
                                            </th>
                                            <th style={{ width: "15%" }} scope="col">
                                                Action
                                            </th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { initialPosts.length > 0 ?
                                        initialPosts?.map((item, index) => (
                                            
                                            <tr key={item.email+index}>
                                                <td style={{ width: "10%" }}>
                                                    {index+1}
                                                </td>
                                                <td style={{ width: "20%" }}>
                                                    {item.name}
                                                </td>
                                                <td style={{ width: "30%" }}>
                                                    {item.email}
                                                </td>
                                                <td style={{ width: "15%" }}>
                                                    {((new Date(item.createdAt).getUTCMonth())+1) + '-' + new Date(item.createdAt).getUTCDate() + '-' + new Date(item.createdAt).getUTCFullYear()}
                                                </td>
                                                <td style={{ width: "10%" }}>
                                                    {item.status}
                                                </td>
                                                <td style={{ width: "15%" }}>
                                                    {
                                                        item.status === "DELETED" ?
                                                        <p>Already Deleted</p>:
                                                        <Button  onMouseDown={(e)=>{handleMouseDownDelete(e, item);} } className="export-btn-inner delete-action">
                                                            DELETE
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )): <tr>
                                            <td colSpan={6} className="text-center">No Record Found</td>
                                            </tr>}
                                    </tbody>
                            </table>
                            </div> 
                            {
                                showLoadMoreButton &&
                                <div className="text-center load-btn">
                                    <button onClick={loadMore} type="button" className="btn ">
                                        Load More...
                                    </button>
                                </div>
                            }  
                        </div>
                    </div>
                </div>
                <DeleteUserModal
                    showModal={delete_modal_show}
                    handleClose={handleUserDeleteClose}
                    selectedEmail={selectedEmail}
                    handleUserDelete={handleUserDelete}
                />
                {isLoading?(
                <Loader />
                ):null}
            </section>
            <SignInAsUser></SignInAsUser></div>
        </>
    );
};
export default UserManagementList;
