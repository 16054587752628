import { useState } from "react";
import { useHttpClient } from "../../../hooks/HttpHook";
import EndPoint from "../../../api/Endpoint";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from "../../../api/Constants";

const AlertsModel = () => {
    const [modulesList, setModulesList] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [show, setShow] = useState(false);
    const { sendRequest } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    const [idOfAlert, setIdOfAlert] = useState('');
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showViewAlert, setShowViewAlert] = useState(false);
    const [showNewAlert, setShowNewAlert] = useState(false);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const fetchData = async (pageArg, initialLoad) => {
        let body ={
            page: pageArg
        };

        // api Call
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.ALERT_LIST,
                "POST",
                body
            );

            setIsLoading(false);
            // const lengthData = responseData.allAlerts.length;
            const lengthData = pageArg * Constants.PAGINATION;
            const totalCountVar = responseData.totalNumOfAlerts;

            if(lengthData >= totalCountVar){
                setShowLoadMoreButton(false);
            }else{
                setShowLoadMoreButton(true);
            }
            const initialDataArr = responseData.allAlerts; 
            if(initialLoad) {
                setModulesList(initialDataArr);
            } else {
                setModulesList((prevList) => [...prevList, ...initialDataArr]);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const openDeleteAlertModal = (event, id) => {
        setIdOfAlert(id);
        setShowDeleteAlert(true);
    };
    
    const closeDeleteAlertModal = () => {
        setIdOfAlert('');
        setShowDeleteAlert(false);
    };

    const handleAlertDelete = async() => {
        const body={
            noOfrecords: Constants.PAGINATION,
            page: pageNumber
        }

        try {
            setIsLoading(true);
            const responseData = await sendRequest(
              EndPoint.DELETE_ALERT+`${idOfAlert}`,
              "POST",
              body
            );

            setIsLoading(false);
            closeDeleteAlertModal();

            if(responseData.records.length == responseData.count){
                setShowLoadMoreButton(false);
            }else{
                setShowLoadMoreButton(true);
            }

            if(responseData.records.length > 0){
                toast.success(`${responseData.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
              setModulesList(responseData.records);
            }else if(responseData.records.length == 0){
                setModulesList([]);
            }
        } catch (err) {
            setIsLoading(false);
            toast.error('Alert delete unsuccessful', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            closeDeleteAlertModal();
            console.log(err);
        }
    }

    const handleLoadMore = () =>{
        const pageCount = pageNumber;
        setPageNumber(pageCount+1);
        fetchData(pageCount+1);
    }

    const openViewAlertModal = async(event, id) => {
        setIsLoading(true);
        const mId = id._id;
    
        try {
            const responseData = await sendRequest(
              EndPoint.GET_ALERT+`${mId}`,
              "GET"
            );
    
            if(responseData.data){
                setViewData(responseData.data);
            } else {
                setViewData([]);
                toast.error('Alert data not found, Something went wrong!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            
            setShowViewAlert(true);
        } catch (err) {
            toast.error('Alert data not found, Something went wrong!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            closeViewAlertModal();
            console.log(err);
        }
        
        setIsLoading(false);
    };
    
    const closeViewAlertModal = () => {
        setIdOfAlert('');
        setShowViewAlert(false);
    };

    const openNewAlertModal = (event, id) => {
        setShowNewAlert(true);
    };
    
    const closeNewAlertModal = () => {
        setShowNewAlert(false);
    };

    const handleFileDownload = async(event, id, attachment) => {
        
        try {
            setIsLoading(true);
            const responseData = await sendRequest(
              EndPoint.GET_ALERT_FILE+`${attachment}`,
              "GET",
            );

            setIsLoading(false);
            closeDeleteAlertModal();
            
            if (!responseData.error) {
                console.log(responseData.data);
                const url = responseData.data;
                const fileName = responseData.name;
            
                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName; // Set the file name for the download
            
                // Append the link element to the document body
                document.body.appendChild(a);
                a.target = '_blank';
                // Trigger a click event on the link to initiate the download
                a.click();
            
                // Remove the link element from the document body
                document.body.removeChild(a);
            
                // Revoke the Blob URL to free up memory
                URL.revokeObjectURL(url);

                /* toast.success('File fetched successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }); */
            }

        } catch (err) {
            setIsLoading(false);
            toast.error('File not found on bucket', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            closeDeleteAlertModal();
            console.log(err);
        }
    }

    return{
        show,
        modulesList,
        isLoading,
        fetchData,
        viewData,

        modulesList,
        setModulesList,
        showLoadMoreButton,
        setShowLoadMoreButton,

        idOfAlert,
        showDeleteAlert,
        openDeleteAlertModal,
        closeDeleteAlertModal,
        handleAlertDelete,
        handleLoadMore,

        showViewAlert,
        openViewAlertModal,
        closeViewAlertModal,

        openNewAlertModal,
        closeNewAlertModal,
        showNewAlert,

        handleFileDownload,
    }
}

export default AlertsModel;