import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const FlyerDeleteModal = (prop) => {
    return (
        <>
        <Modal show={prop.showModal} onHide={prop.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Delete Flyer Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Are you sure you want to delete this flyer? It will permanenly delete the folder and all the included files.</p>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={prop.handleClose}>CANCEL</Button>
            <Button variant="primary" onClick={prop.handleFlyerDelete}>CONFIRM</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default FlyerDeleteModal;