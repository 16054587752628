import React from "react";
import { Button } from "react-bootstrap";
import {Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import LogoInner from "../../assets/img/logo-inner.svg";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import { useHttpClient } from "../../hooks/HttpHook";

const Navbar = () => {

  const logoutAPI = EndPoint.LOGOUT_API_URL;
  const { sendRequest } = useHttpClient();

  const handleLogout = async () => {
    try {
      const response = await sendRequest(
        logoutAPI,
        "GET"
      );

      if (response) {
        logoutFb();
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const logoutFb = ()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href= `${Constants.FRONT_BASE_PATH}/admin/login`;;  
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
      <div className="container">
      <Link className="navbar-brand" to={`${Constants.FRONT_BASE_PATH}/`} >
      <img src={LogoInner}/>
      </Link>
      

      
        
        <div className="header-right ">
          <Dropdown >
            <Dropdown.Toggle  id="dropdown-basic">
              {JSON.parse( localStorage.getItem('user')).name?JSON.parse( localStorage.getItem('user')).name:'Admin'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item >
              {(localStorage.getItem('token') == null)?(
                <Link className="nav-link" to="/login">
                Login
                </Link>
              ):(
                <>
                  <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/user-management-list`}>
                    User List
                  </Link>
                  <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/version-history`}>
                    App version history
                  </Link>

                  <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/access-modules`}>
                    Access Modules
                  </Link>

                  <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/alerts`}>
                    Merch Alerts
                  </Link>

                  <div className="nav-link" onClick={handleLogout} /* onClick={logoutFb} */>
                  Logout
                  </div>
                </>
            )}
              </Dropdown.Item>
              
            </Dropdown.Menu>
          </Dropdown>
          
        </div>
      </div>
    </nav>
  );
};
export default Navbar;