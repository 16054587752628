import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loading/Loader";
import axios from "axios";
import EndPoint from "../../api/Endpoint";

import pdfIcon from "../../assets/img/file-plus-regular.svg";
import imageIcon from "../../assets/img/file-plus-regular.svg";
import CloseIcon from "../../assets/img/modal-close-icon.svg";
import { Document, Page } from "react-pdf";

const AddEditFlyerModal = (props) => {
    const { showModal, handleClose, flyerData, fetchData, mcategoryName } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [inputError, setInputError] = useState(null);
    
    const [attachment, setAttachment] = useState("");
    const [attachmentPreview, setAttachmentPreview] = useState(null);

    useEffect(() => {
        if (showModal) {
            if (flyerData) {
                // If flyerData is provided, it's an edit mode
                setCategory(flyerData.categoryName);
            } else {
                // Reset fields for adding new flyer
                setCategory("");
                setInputError("");
            }
            setFormValid(!!flyerData); // Enable form if editing or flyerData exists
        }
    }, [showModal, flyerData]);

    const handleAddEditFlyer = async () => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            let endpoint;
            let method = 'post';
            
            if (flyerData) { // If flyerData exists, it's an edit operation
                endpoint = `${EndPoint.EDIT_DIGITAL_FLYER}/${flyerData}`;
            } else {
                endpoint = EndPoint.ADD_DIGITAL_FLYER;
                formData.append("categoryName", category);
            }

            if (attachment) {
                formData.append("file", attachment);
            }

            const token = localStorage.getItem("token");
            const response = await axios[method](endpoint, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status !== 200) {
                throw new Error(response.message);
            }

            setIsLoading(false);
            toast.success(flyerData ? "Flyer Updated successfully" : "Flyer added successfully");
            setAttachment("");
            setAttachmentPreview(null);
            handleClose();
            fetchData(1, true);
        } catch (error) {
            setIsLoading(false);
            console.error("Error in saving Flyer:", error);

            if (error.response) {
                const errorMessage = error.response.data.message || "Failed to save Flyer";
                toast.error(errorMessage);

                if (error.response.status === 403) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    window.location.href = `${EndPoint.FRONT_LOGIN_URL}`;
                }
            } else {
                // Handle other errors without a response object
                toast.error("Failed to save Flyer");
            }
        }
    };

    const handleCategoryChange = (event) => {
        const inputValue = event.target.value;
        const regexPattern = /^[ A-Za-z0-9.\-~_=:\b]*$/;

        if (inputValue === '' || regexPattern.test(inputValue)) {
            setCategory(inputValue);
            setFormValid(!!inputValue);
            setInputError(null);
        } else {
            setCategory(inputValue);
            setFormValid(!inputValue);
            setInputError("Only . - ~ _ = : special characters are allowed");
        }
    };

    const handleChange = (event) => {
        const file = event.target.files[0];
        setAttachment(file);

        if (file && file.type.startsWith("image")) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setAttachmentPreview(reader.result);
            };
        } else if (file && file.type === "application/pdf") {
            setAttachmentPreview(file);
        } else if (file && (file.type !== "application/pdf" || !file.type.startsWith("image"))) {
            toast.error('Please select only pdf or image type file.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            // Handle unsupported file types
            console.warn("Unsupported file type:");
        }
        event.target.value = null;
    };

    const handleClosePreview = (event) => {
        event.preventDefault();
        setAttachment("");
        setAttachmentPreview(null);
    };

    const handleCloseFilter = () => {
        setAttachment("");
        setAttachmentPreview(null);
        handleClose();
    };

    return (
        <>
            <Modal show={showModal} onHide={handleCloseFilter} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{flyerData ? 'EDIT' : 'CREATE NEW'} DIGITAL FLYER</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name:</Form.Label>
                            
                            {flyerData ? (
                                <span className="categoryNameOnEdit">{mcategoryName}</span>
                                ) : (
                                <>
                                    <Form.Control
                                    type="text"
                                    maxLength={100}
                                    value={category}
                                    onChange={handleCategoryChange}
                                    placeholder="Enter Name"
                                    />
                                    {inputError && <span className="mErrorMessage">{inputError}</span>}
                                </>
                            )}

                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Upload generic file: </Form.Label>
                            <label className="pdf-modal-dragbox alert-image-box" htmlFor="pdf">
                                <Form.Control type="file" id="pdf" onChange={(e) => handleChange(e)} accept=".pdf, image/*" />
                               {attachmentPreview&&<div className="pdf-close" onClick={handleClosePreview}><img src={CloseIcon} alt="Close Icon" /></div>} 
                                {/* <Row> */}
                                    {/* <Col> */}
                                        {attachmentPreview && (
                                            typeof attachmentPreview === "string" ? (
                                                <img src={attachmentPreview} alt="Attachment Preview" />
                                            ) : (
                                                <Document
                                                    file={attachmentPreview}
                                                    noData={<div className="pdf-drag-drop-text"><div className="pdf-drag-drop-icon"><img src={pdfIcon} alt="PDF Icon" /></div>Drag & Drop<br />or <span>browse</span></div>}
                                                >
                                                    <Page pageNumber={1} width={500} height={263} />
                                                </Document>
                                            )
                                        )}
                                        {!attachmentPreview && (
                                            <div className="pdf-drag-drop-text">
                                                <div className="pdf-drag-drop-icon">
                                                    <img src={attachment?.type === "application/pdf" ? pdfIcon : imageIcon} alt="File Icon" />
                                                </div>
                                                Drag & Drop or <span>Browse</span>
                                            </div>
                                        )}
                                    {/* </Col> */}
                                {/* </Row> */}
                            </label>
                            <Form.Text className="text-muted">
                                Allowed file types : PDF, JPG, PNG and JPEG
                            </Form.Text>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAddEditFlyer} disabled={!formValid}>
                        {flyerData ? 'UPDATE' : 'ADD'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            {isLoading && <Loader />}
        </>
    );
};

export default AddEditFlyerModal;
