import React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from 'react-router-dom';
import { authenticationService } from '../_services';
export const PrivateRoute = ({role, Component}) => {
    let currentUser = authenticationService.currentUserValue;
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    let roleToken = authenticationService.fetchedTokenData();

    useEffect(()=>{ //console.log('admin side private route', isAuth);
        if(isAuth) {
            currentUser = authenticationService.currentUserValue;
            roleToken = authenticationService.fetchedTokenData();
            //console.log("Role of user", role, currentUser, roleToken);
        }
    },[isAuth]);

    if (!currentUser && !isAuth) {
        //console.log('in condition', isAuth);
        // not logged in so redirect to login page with the return url
        return <Navigate to='/login' />
    }
    
    
    // check if route is restricted by role
    if (role && role.indexOf(roleToken.role) === -1 || role != 'ADMIN') {
        // role not authorised so redirect to home page
        return <Navigate to='/' />
    }
    
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return currentUser || isAuth ? <Component /> : <Navigate to="/login" />;
}