import { useState } from "react";
import { useHttpClient } from "../../hooks/HttpHook";
import EndPoint from "../../api/Endpoint";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from "../../api/Constants";

const DigitalFlyersModel = () => {
    const [modulesList, setModulesList] = useState([]);
    const [flyerData, setFlyerData] = useState([]);
    const { sendRequest } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    const [idOfAlert, setIdOfAlert] = useState('');
    const [showDeleteFlyer, setShowDeleteFlyer] = useState(false);
    const [addEditFlyerModal, setAddEditFlyerModal] = useState(false);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [initLoadMore, setInitLoadMore] = useState(true);
    const [mcategoryName, setMcategoryName] = useState("");
    

    const fetchData = async (pageArg, initialLoad) => {
        let body = {
            page: pageArg
        };

        // api Call
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.DIGITAL_FLYER_LIST,
                "POST",
                body
            );

            setIsLoading(false);

            const lengthData = pageArg * Constants.PAGINATION;
            const totalCountVar = responseData.totalNumOfFlyers;

            if (lengthData >= totalCountVar) {
                setShowLoadMoreButton(false);
            } else {
                setShowLoadMoreButton(true);
            }
            const initialDataArr = responseData.allFlyersCategory;
            if (initialLoad) {
                setModulesList(initialDataArr);
                setInitLoadMore(false);
            } else {
                setModulesList((prevList) => [...prevList, ...initialDataArr]);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const openAddEditFlyerModal = (event, element, categoryName) => {
        event.preventDefault();
        setFlyerData(element);
        setMcategoryName(categoryName);
        setAddEditFlyerModal(true);
    };

    const closeAddEditFlyerModal = () => {
        setAddEditFlyerModal(false);
    };

    const handleLoadMore = () => {
        const pageCount = pageNumber;
        setPageNumber(pageCount + 1);
        fetchData(pageCount + 1);
    }

    const openDeleteFlyerModal = (event, id) => {
        setIdOfAlert(id);
        setShowDeleteFlyer(true);
    };

    const closeDeleteFlyerModal = () => {
        setIdOfAlert('');
        setShowDeleteFlyer(false);
    };

    const handleFlyerDelete = async () => {
        const body = {
            noOfrecords: Constants.PAGINATION,
            page: pageNumber
        }

        try {
            setIsLoading(true);
            const responseData = await sendRequest(
                EndPoint.DELETE_FLYER + `${idOfAlert}`,
                "POST",
                body
            );

            setIsLoading(false);
            closeDeleteFlyerModal();

            // if (!responseData.error) {
            //     setShowLoadMoreButton(false);
            // } else {
            //     setShowLoadMoreButton(true);
            // }

            if (!responseData.error) {
                toast.success(`${responseData.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // setModulesList(responseData.records);
                // Remove the deleted flyer from the existing modules list
                setModulesList(prevModulesList => 
                    prevModulesList.filter(module => module._id !== idOfAlert)
                );
            } else {
                setModulesList([]);
            }
        } catch (err) {
            setIsLoading(false);
            toast.error('Flyer delete unsuccessful', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            closeDeleteFlyerModal();
            console.log(err);
        }
    }

    return {
        isLoading,
        setIsLoading,
        modulesList,
        setModulesList,
        showLoadMoreButton,
        setShowLoadMoreButton,
        idOfAlert,
        fetchData,
        openAddEditFlyerModal,
        closeAddEditFlyerModal,
        addEditFlyerModal,
        handleLoadMore,
        showDeleteFlyer,
        handleFlyerDelete,
        openDeleteFlyerModal,
        closeDeleteFlyerModal,
        flyerData,
        setModulesList,
        initLoadMore,
        mcategoryName
    }
}

export default DigitalFlyersModel;