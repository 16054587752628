import { useState } from "react";
import { useHttpClient } from "../../../hooks/HttpHook";
import EndPoint from "../../../api/Endpoint";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccessModulesModel = () => {
    const [modulesList, setModulesList] = useState([]);
    const [show, setShow] = useState(false);
    const { sendRequest } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    const fetchData = async (osV, page) => {

        try {
            setIsLoading(true);
            const responseData = await sendRequest(
                EndPoint.LISTING_OF_MODULES,
                "GET",
            );

            if(responseData.records.length > 0){
                setModulesList(responseData.records);
            }else if(responseData.records.length == 0){
                setModulesList(responseData.records);
            }

            setIsLoading(false);

        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    };

    return{
        show,
        modulesList,
        isLoading,
        fetchData,
    }
}

export default AccessModulesModel;