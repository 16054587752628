import React from "react";
import Constants from "../../api/Constants";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const SignInAsUser = () => {

  const [token, setToken] = useState('');

  useEffect(() => {
      // Assuming you have stored the token in local storage when it was generated
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
      setToken(storedToken);
      }
  }, []);

  const handleSignInAsUser = async () => {
      try {
        // Redirect to the frontend application with the existing token
        window.open(`${Constants.REACT_APP_FRONT_APPLICATION_BASE_URL}/user/authenticate/${token}`, "_self");
        } catch (error) {
        console.error('Error redirecting:', error);
        }
    };

  return (
    <div className="d-flex justify-content-end mt-5 sign-in-link"> 
        <Link onClick={handleSignInAsUser}>Sign In As User?</Link>
    </div> 
  );
};
export default SignInAsUser;
